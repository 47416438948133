var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.editDialog,"width":"600","origin":"bottom right","persistent":""}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Sửa đổi")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-container',[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ngày Giao Dịch","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.transfer.transferDate),callback:function ($$v) {_vm.$set(_vm.transfer, "transferDate", $$v)},expression:"transfer.transferDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.transferDatePicker),callback:function ($$v) {_vm.transferDatePicker=$$v},expression:"transferDatePicker"}},[_c('v-date-picker',{staticClass:"ma-0 pa-0",on:{"input":function($event){_vm.transferDatePicker = false}},model:{value:(_vm.transfer.transferDate),callback:function ($$v) {_vm.$set(_vm.transfer, "transferDate", $$v)},expression:"transfer.transferDate"}})],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"type":"number","min":"0","label":"Số tiền","prepend-inner-icon":"mdi-cash"},model:{value:(_vm.transfer.amounts),callback:function ($$v) {_vm.$set(_vm.transfer, "amounts", _vm._n($$v))},expression:"transfer.amounts"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.countryOptions,"label":"Loại tiền","prepend-inner-icon":"mdi-currency-sign"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"size":12,"color":item.color}},[_vm._v(" mdi-checkbox-blank-circle ")]),_c('span',[_vm._v(_vm._s(item.text))])],1)]}}]),model:{value:(_vm.transfer.country),callback:function ($$v) {_vm.$set(_vm.transfer, "country", $$v)},expression:"transfer.country"}})],1)],1),_c('v-text-field',{attrs:{"label":"Tỉ giá","prepend-inner-icon":"mdi-currency-sign","readonly":""},model:{value:(_vm.transfer.rate),callback:function ($$v) {_vm.$set(_vm.transfer, "rate", $$v)},expression:"transfer.rate"}}),_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","label":"Nội dung","prepend-inner-icon":"mdi-comment-outline"},model:{value:(_vm.transfer.comment),callback:function ($$v) {_vm.$set(_vm.transfer, "comment", $$v)},expression:"transfer.comment"}}),(_vm.user.admin)?_c('v-autocomplete',{attrs:{"items":_vm.userOptions,"label":"User","prepend-inner-icon":"mdi-account-outline"},model:{value:(_vm.transfer.userId),callback:function ($$v) {_vm.$set(_vm.transfer, "userId", $$v)},expression:"transfer.userId"}}):_vm._e(),_c('v-row',[_c('v-col',[(_vm.user.admin)?_c('v-autocomplete',{attrs:{"items":_vm.typeOptions,"label":"Loại","prepend-inner-icon":"mdi-cog-transfer-outline"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"size":12,"color":item.color}},[_vm._v(" mdi-checkbox-blank-circle ")]),_c('span',[_vm._v(_vm._s(item.text))])],1)]}}],null,false,4163024032),model:{value:(_vm.transfer.type),callback:function ($$v) {_vm.$set(_vm.transfer, "type", $$v)},expression:"transfer.type"}}):_vm._e()],1),_c('v-col',[(_vm.user.admin)?_c('v-autocomplete',{attrs:{"items":_vm.statusOptions,"label":"Trạng thái","prepend-inner-icon":"mdi-cog-transfer-outline"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"size":12,"color":item.color}},[_vm._v(" mdi-checkbox-blank-circle ")]),_c('span',[_vm._v(_vm._s(item.text))])],1)]}}],null,false,4163024032),model:{value:(_vm.transfer.status),callback:function ($$v) {_vm.$set(_vm.transfer, "status", $$v)},expression:"transfer.status"}}):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"type":"submit","depressed":"","color":"success"}},[_vm._v(" CHẤP NHẬN ")]),_c('v-btn',{staticClass:"mr-0",attrs:{"color":"grey","text":""},on:{"click":_vm.cancel}},[_vm._v(" HỦY BỎ ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }